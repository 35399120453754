@font-face {
  font-family: 'flexiform';
  src: url('../font/flexiform.eot?33180470');
  src: url('../font/flexiform.eot?33180470#iefix') format('embedded-opentype'),
       url('../font/flexiform.woff2?33180470') format('woff2'),
       url('../font/flexiform.woff?33180470') format('woff'),
       url('../font/flexiform.ttf?33180470') format('truetype'),
       url('../font/flexiform.svg?33180470#flexiform') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'flexiform';
    src: url('../font/flexiform.svg?33180470#flexiform') format('svg');
  }
}
*/
[class^="form-"]:before, [class*=" form-"]:before {
  font-family: "flexiform";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.form-resize-full:before { content: '\e800'; } /* '' */
.form-check:before { content: '\e801'; } /* '' */
.form-menu-1:before { content: '\e802'; } /* '' */
.form-dot-3:before { content: '\e803'; } /* '' */
.form-clock:before { content: '\e804'; } /* '' */
.form-phone:before { content: '\e805'; } /* '' */
.form-cog:before { content: '\e806'; } /* '' */
.form-down-open:before { content: '\e807'; } /* '' */
.form-left-open:before { content: '\e808'; } /* '' */
.form-right-open:before { content: '\e809'; } /* '' */
.form-up-open:before { content: '\e80a'; } /* '' */
.form-edit:before { content: '\e80b'; } /* '' */
.form-down:before { content: '\e80c'; } /* '' */
.form-left:before { content: '\e80d'; } /* '' */
.form-right:before { content: '\e80e'; } /* '' */
.form-up:before { content: '\e80f'; } /* '' */
.form-ok:before { content: '\e810'; } /* '' */
.form-up-open-mini:before { content: '\e811'; } /* '' */
.form-right-open-mini:before { content: '\e812'; } /* '' */
.form-left-open-mini:before { content: '\e813'; } /* '' */
.form-down-open-mini:before { content: '\e814'; } /* '' */
.form-sort-alphabet:before { content: '\e815'; } /* '' */
.form-sort-numeric:before { content: '\e816'; } /* '' */
.form-calendar:before { content: '\e817'; } /* '' */
.form-move-1:before { content: '\e818'; } /* '' */
.form-arrows-ccw:before { content: '\e819'; } /* '' */
.form-arrow-combo:before { content: '\e81a'; } /* '' */
.form-upload:before { content: '\e81b'; } /* '' */
.form-ok-circled2:before { content: '\e81c'; } /* '' */
.form-camera:before { content: '\e81d'; } /* '' */
.form-camera-1:before { content: '\e81e'; } /* '' */
.form-globe:before { content: '\e81f'; } /* '' */
.form-globe-1:before { content: '\e820'; } /* '' */
.form-attach:before { content: '\e821'; } /* '' */
.form-attach-1:before { content: '\e822'; } /* '' */
.form-calendar-1:before { content: '\e823'; } /* '' */
.form-trash-empty:before { content: '\e825'; } /* '' */
.form-upload-cloud:before { content: '\f014'; } /* '' */
.form-check-empty:before { content: '\f096'; } /* '' */
.form-resize-full-alt:before { content: '\f0b2'; } /* '' */
.form-docs:before { content: '\f0c5'; } /* '' */
.form-list-bullet:before { content: '\f0ca'; } /* '' */
.form-sort:before { content: '\f0dc'; } /* '' */
.form-sort-down:before { content: '\f0dd'; } /* '' */
.form-sort-up:before { content: '\f0de'; } /* '' */
.form-sitemap:before { content: '\f0e8'; } /* '' */
.form-doc-text:before { content: '\f0f6'; } /* '' */
.form-angle-left:before { content: '\f104'; } /* '' */
.form-angle-right:before { content: '\f105'; } /* '' */
.form-angle-up:before { content: '\f106'; } /* '' */
.form-angle-down:before { content: '\f107'; } /* '' */
.form-circle-empty:before { content: '\f10c'; } /* '' */
.form-circle:before { content: '\f111'; } /* '' */
.form-ellipsis-vert:before { content: '\f142'; } /* '' */
.form-dot-circled:before { content: '\f192'; } /* '' */
.form-graduation-cap:before { content: '\f19d'; } /* '' */
.form-file-pdf:before { content: '\f1c1'; } /* '' */
.form-file-word:before { content: '\f1c2'; } /* '' */
.form-file-excel:before { content: '\f1c3'; } /* '' */
.form-file-powerpoint:before { content: '\f1c4'; } /* '' */
.form-file-image:before { content: '\f1c5'; } /* '' */
.form-file-archive:before { content: '\f1c6'; } /* '' */
.form-circle-notch:before { content: '\f1ce'; } /* '' */
.form-circle-thin:before { content: '\f1db'; } /* '' */
.form-toggle-off:before { content: '\f204'; } /* '' */
.form-toggle-on:before { content: '\f205'; } /* '' */
.form-street-view:before { content: '\f21d'; } /* '' */
.form-object-group:before { content: '\f247'; } /* '' */
.form-map-signs:before { content: '\f277'; } /* '' */
.form-map-o:before { content: '\f278'; } /* '' */
.form-credit-card-alt:before { content: '\f283'; } /* '' */
.form-wpforms:before { content: '\f298'; } /* '' */
.form-envelope-open-o:before { content: '\f2b7'; } /* '' */
